<template>
  <v-dialog :value="value" persistent max-width="450px" @input="$emit('input', $event)">
    <validation-observer v-slot="{ handleSubmit }">
      <v-card :disabled="loading" :loading="loading">
        <v-card-title class="grey lighten-5">
          {{ $t("hr.debit_tickets.transfer_approval") }}
          <v-spacer />
          <v-btn depressed icon @click="onClickCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="transferRequest" class="pt-3">
          <v-flex xs12>
            <span
              v-html="
                $t('hr.debit_ticket_transfer_request.approval_message', [
                  `${transferRequest.requester.firstName} ${transferRequest.requester.lastName}`
                ])
              "
            />
          </v-flex>
          <v-flex xs12>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.asset") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.asset.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.quantity") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.quantity || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.borrow_date") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.borrowDate || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.due_date") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.dueDate || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("hr.debit_tickets.give_back_date") }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ debitTicket.giveBackDate || "-" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-flex>
          <v-flex xs12>
            <v-radio-group v-model="isApproved" row mandatory>
              <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
              <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
            </v-radio-group>
          </v-flex>
        </v-card-text>
        <v-card-actions class="dialog-footer">
          <v-spacer></v-spacer>
          <v-btn depressed @click.stop="handleSubmit(onClickSave)">{{ $t("buttons.save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
  export default {
    name: "TransferRequestApprovalDialog",
    props: {
      value: Boolean,
      transferRequest: {
        type: Object,
        required: false
      }
    },
    data: () => ({
      loading: false,
      isApproved: false
    }),
    computed: {
      debitTicket() {
        return this.transferRequest ? this.transferRequest.debitTicketToBeTransferred : null;
      }
    },
    methods: {
      onClickCancel() {
        this.isApproved = false;
        this.$emit("input", false);
      },
      onClickSave() {
        this.loading = true;
        this.$api.debitTicketService
          .transferApproval(this.transferRequest.id, this.isApproved)
          .then(response => {
            if (!response.data.error) {
              this.onClickCancel();
              this.$emit("success");
            } else if (response.data.error.code === 753) {
              this.$helpers.showNotification(
                this.$t("hr.debit_tickets.DATA_INTEGRITY_VIOLATION_EXCEPTION"),
                "error",
                true
              );
            }
          })
          .catch(console.error)
          .then(() => (this.loading = false));
      }
    }
  };
</script>

<style scoped></style>
